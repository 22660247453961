<template>
    <div class="email-verify">
        <div class="container">
            <div v-if="this.status == 0" class="content">
                <div class="skeleton-loading img"></div>
                <div class="skeleton-loading h1"></div>
                <div class="skeleton-loading p"></div>
            </div>
            <div v-if="this.status != 0" class="content">
                <img v-if="this.status == 1" class="success" src="../assets/images/success.svg" alt="">
                <img v-if="this.status == 2" class="failed" src="../assets/images/failed.svg" alt="">
                <h1>{{ this.status == 1 ? '驗證成功' : '驗證失敗' }}</h1>
                <p v-if="this.status == 1">您已完成信箱驗證，將於 {{ this.toHome }} 秒後跳轉至首頁...</p>
                <p v-if="this.status == 2">{{ this.errMsg }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import {apiEmailVerify} from '@/apis/account.js';

export default {
    name: 'EmailVerify',
    components: {
    },
    data() {
        return{
            status: 0, // 0: waiting, 1: true, 2: false
            toHome: 5,
            errMsg: ""
        }
    },
    methods: {
        backToHome(){
            setInterval(() => {
                this.toHome -= 1;
                if(this.toHome == 0)window.location = '/';
            }, 1000)
        },
        verifyEmail(){
            apiEmailVerify({uid: this.$route.params.uid, vid: this.$route.params.vid}).then(res => {
                if(res.data.status){
                    this.status = 1
                    this.result = "驗證成功"
                    this.backToHome()
                }else{
                    this.status = 2
                    this.errMsg = res.data.msg
                }
            })
        }
    },
    mounted() {
        this.verifyEmail()
    },
}
</script>